import GlobalService from '../../services/global.service';
import * as localforage from 'localforage';

export const global = {
  namespaced: true,
  state: {
    EnumDetails: {},
    Countries: [],
    States: [],
    companyCategory: [],
    jobTitles: [],
    jobCategories: [],
    Jobs: [],
    jobsPayload: {},
    Applications: [],
    resumeUrls: [],
    jobForm: {},
  },
  getters: {
    getJob:
      ({ Jobs }) =>
      async (id) => {
        try {
          if (Array.isArray(Jobs) && Jobs.length > 0) {
            return Jobs.filter((job) => job.fid == id)[0] || null;
          }
          const jobs = await localforage.getItem('Jobs');
          return jobs.filter((job) => job.fid == id)[0] || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getResumeUrl:
      ({ resumeUrls }) =>
      async (file_name) => {
        try {
          if (Array.isArray(resumeUrls) && resumeUrls.length > 0) {
            return resumeUrls.filter((url) => url.key === file_name)[0]?.url || null;
          }
          const resume_urls = JSON.parse(await localforage.getItem('resume_urls'));
          return resume_urls.filter((url) => url.key === file_name)[0]?.url || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getJobs(state) {
      return state.Jobs;
    },
    getApplications(state) {
      return state.Applications;
    },
    getCountries: ({ Countries }) => Countries,
    getAllJobs: ({ Jobs }) => Jobs,
    getJobsPayload: ({ jobsPayload }) => jobsPayload,
    getJobForm: ({ jobForm }) => jobForm,
    getEnumDetails: ({ EnumDetails }) => EnumDetails,
    getEnum:
      ({ EnumDetails }) =>
      async (enumDetail) => {
        try {
          if (Object.keys(EnumDetails).length > 0) {
            return EnumDetails[enumDetail];
          }
          const enumDetails = await localforage.getItem('EnumDetails');
          return enumDetails[enumDetail];
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
  },
  mutations: {
    setResumeUrl(state, payload) {
      const idx = state.resumeUrls.findIndex((resume) => resume.key === payload.key);
      if (idx == -1) {
        state.resumeUrls.push(payload);
      } else {
        state.resumeUrls[idx] = payload;
      }
      localforage.setItem('resume_urls', JSON.stringify(state.resumeUrls));
    },
    Countries(state, payload) {
      state.Countries = [...payload];
    },
    States(state, payload) {
      state.States = payload;
    },
    setEnum(state, payload) {
      localforage.setItem('EnumDetails', payload);
      state.EnumDetails = payload;
    },
    setCompanyCategory(state, payload) {
      state.companyCategory = payload;
    },
    setJobTitles(state, payload) {
      state.jobTitles = payload;
    },
    setJobCategories(state, payload) {
      state.jobCategories = payload;
    },
    setJobs(state, { data, links, meta }) {
      localforage.setItem('Jobs', data);
      state.Jobs = data;
      state.jobsPayload = { links, meta };
    },
    setApplications(state, payload) {
      state.Applications = payload;
    },
    setJobForm(state, payload) {
      if (payload.key) {
        state.jobForm[payload.key] = payload.value;
      } else {
        state.jobForm = payload;
      }
    },
  },
  actions: {
    async getEnums({ commit }) {
      const { data } = await GlobalService.getEnum();
      commit('setEnum', data.data);
      return data.data;
    },
    async getCountries({ commit }) {
      const { data } = await GlobalService.getCountries();
      commit('Countries', data.data);
      return data.data;
    },
    async getStates({ commit }, credential) {
      const { data } = await GlobalService.getStates(credential);
      commit('States', data.data);
      return data.data;
    },
    async getCompanyCategory({ commit }) {
      const { data } = await GlobalService.getCompanyCategory();
      commit('setCompanyCategory', data.data);
      return data.data;
    },
    async getJobTitles({ commit }) {
      const { data } = await GlobalService.getJobTitles();
      commit('setJobTitles', data.data);
      return data.data;
    },
    async getJobCategories({ commit }) {
      const { data } = await GlobalService.getJobCategories();
      commit('setJobCategories', data.data);
      return data.data;
    },
    async getAllJobs({ commit }, credential) {
      const { data } = await GlobalService.getAllJobs(credential);
      commit('setJobs', data);
      return data.data;
    },
    async getSingleJob({ commit }, credential) {
      const res = await GlobalService.getSingleJob(credential);
      return res.data.data;
    },
    async applyToJob({ commit }, credential) {
      const res = await GlobalService.applyToJob(credential);
      return res.data.data;
    },
    async getAllApplications({ commit }) {
      const res = await GlobalService.getAllApplications();
      commit('setApplications', res.data.data);
      return res.data.data;
    },
    async getSingleApplication({ commit }, credential) {
      const res = await GlobalService.getSingleApplication(credential);
      return res.data.data;
    },
    async sendContactForm({ commit }, credential) {
      const { data } = await GlobalService.sendContactForm(credential);
      return data.data;
    },
  },
};
