import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);
// Create a global head instance
const head = createHead();
app.config.globalProperties.$candidateUrl = process.env.VUE_APP_CANDIDATE_URL;
app.config.globalProperties.$employerUrl = process.env.VUE_APP_EMPLOYER_URL;

app
  .use(store)
  .use(router)
  .use(head)
  .use(VueLazyload, {
    preLoad: 1.5,
    attempt: 1,
  })
  .mount('#app');
